import React, { useEffect, useRef, useState } from 'react';
import { FlowChart } from '@mrblenny/react-flow-chart';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import { node } from 'prop-types';
import * as Cards from './partials/cards';
import {
  CustomPort,
  CustomCanvas,
  CustomNode,
  CustomLink,
  Loader,
  Thumbnail,
  DraggableView,
  ExportOverlay,
} from './partials/helpers';
import {
  RemoveModal,
  ActivationModal,
  AssignmentModal,
  RuleModal,
} from './partials/modals';
import Toolbar from './partials/toolbar';
import Snackbar from '../../ui/snackbar';
import styles from './flowchart.module.scss';
import {
  ToolbarConfig,
  AssignmentModalConfig,
  ActivationModalConfig,
  ToolTipConfig,
} from './config/index';

const Chart = inject('data')(
  observer(({ data }) => {
    const ref = useRef(null);
    const diagramRef = useRef(null);
    const loaderRef = useRef(null);
    const [isExporting, setIsExporting] = useState(false);
    const [thumbnail, setThumbnail] = useState(false);
    const [draggable, setDraggable] = useState(false);

    const [isLocked, setIsLocked] = useState(true);
    const onExportChart = () => {
      // we're appending activation conditions outside the diagram
      // to have them appended to the export
      // however, we don't want the user to see this happen, so we've to
      // block the overflow temporarily
      if (ref.current === null) {
        return;
      }
      setIsExporting(true);
      data.setLoading(true);
      document.body.style.overflow = 'hidden';
      // small timeout to let the loader appear
      // before the blocking pngexport starts
      setTimeout(() => {
        data.exportChart(ref.current).then(() => {
          setIsExporting(false);
          document.body.style.overflow = 'false';
        });
      });
    };

    const onZoomOut = () => {
      if (ref.current === null) {
        return;
      }
      data.zoomOut(ref.current.querySelector('[data-chart]'));
    };

    const makeThumbnail = () => {
      if (ref.current === null) {
        return;
      }
      // data.zoomOut(ref.current.querySelector('[data-chart]'));
      setThumbnail(true);
      data.setThumbnail(true);
    };

    const onLock = () => ({
      color: 'stable-500',
      disabled: toJS(data.forceLock),
      icon: {
        name: data.customConfig.readonly ? 'locked' : 'unlocked',
        height: 18,
        width: 20,
      },
      action: () => {
        setIsLocked(!data.customConfig.readonly);
        data.setReadonly(!data.customConfig.readonly);
      },
    });

    useEffect(() => {
      if (ref.current && typeof window !== 'undefined') {
        const setHeight = () => {
          const height = window.innerHeight - ref.current.offsetTop;
          ref.current.style.minHeight = `${height}px`;
          // ref.current.style.height = `${height}px`;
          ref.current.querySelector(
            '[data-chart]'
          ).style.minHeight = `${height}px`;
          ref.current.querySelector(
            '[data-chart]'
          ).style.maxHeight = `${height}px`;

          if (loaderRef.current) {
            loaderRef.current.style.maxHeight = `${height}px`;
          }
        };

        setHeight();
        data.setViewport(ref.current);
        const resizeObserver = new ResizeObserver(() => {
          setHeight();
          data.setViewport(ref.current);
        });
        resizeObserver.observe(document.body);
      }
    }, []);

    return Object.keys(data.chart.nodes).length > 0 ? (
      <div className={styles.flowchartWrapper} ref={ref}>
        {data.loading && <Loader ref={loaderRef} />}
        {data.snackbars?.length > 0 && (
          <div className={styles.snackbarWrapper}>
            {toJS(data.snackbars).map((snackbar) => (
              <Snackbar
                {...snackbar}
                key={snackbar.id}
                className={styles.snackbar}
              />
            ))}
          </div>
        )}
        <div
          className={styles.diagramWrapper}
          data-export-container
          ref={diagramRef}
          id={'diagram'}
        >
          {draggable && (
            <DraggableView
              offset={toJS(data.chart.offset)}
              scale={toJS(data.chart.scale)}
              dragCanvas={(x, y, scale) => data.setView(x, y, scale)}
              diagramRef={diagramRef}
              nodes={toJS(data.nodes)}
            />
          )}
          {thumbnail && (
            <Thumbnail
              diagramRef={diagramRef}
              clearThumb={() => setThumbnail(false)}
            />
          )}
          <FlowChart
            className={styles.flowwrap}
            chart={toJS(data.chart)}
            callbacks={toJS(data.funnelActions)}
            config={toJS(data.customConfig)}
            readonly
            Components={{
              Port: ({ port }) => <CustomPort port={port} iconModule arrow />,
              CanvasOuter: CustomCanvas,
              Node: CustomNode,
              NodeInner: ({ node, otherProps, config }) => {
                const CustomNodeInner = Cards[node.component]
                  ? Cards[node.component]
                  : Cards.QuestionCard;
                // Set URL to subset url when it is a subsetcard
                const url =
                  node.component == 'SubsetCard'
                    ? data.paths.subset.replace(
                        '/-1',
                        `/${node.props.title.id}`
                      )
                    : false;

                if (
                  node.component === 'QuestionCard' ||
                  node.component === 'AssignmentCard'
                ) {
                  node.props.disabled = config.readonly;
                }

                return (
                  <CustomNodeInner
                    key={node.id}
                    id={node.id}
                    nodePorts={node.ports}
                    data={data}
                    link={url}
                    {...{
                      ...node.props,
                      ...ToolTipConfig(
                        node.component === 'AssignmentCard'
                          ? () => data.editAssignment(node.id, node)
                          : data.paths.edit_rule
                              .replace('/-1', `/${node.id}`)
                              .replace('node_', ''),
                        () => data.deleteNode(node.id, node)
                      ),
                    }}
                  />
                );
              },
              Link: CustomLink,
            }}
          />
          {isExporting && data?.chart?.links && (
            <div className={styles.activationsWrapper} data-legenda>
              {data.customConfig?.subset && (
                <ExportOverlay
                  detail={{
                    label: {
                      markdown: {
                        text: data.customConfig.subset?.title,
                      },
                    },
                    value: {
                      text: data.customConfig.subset?.name,
                      color: 'positive',
                      size: 'h3',
                    },
                  }}
                />
              )}
              {Object.values(data.chart.links)
                .filter((l) => l.condition)
                .map((link, index) => (
                  <Cards.ActivationCard
                    key={index}
                    link={link.id}
                    size={'xxs'}
                    diagram
                    color={'balanced-500'}
                    title={{
                      text: link?.to?.nodeId?.includes('assignment')
                        ? 'Execution condition'
                        : 'Activation condition',
                    }}
                    activation_rule={{
                      rule: link.condition,
                    }}
                    open={'hidden'}
                  />
                ))}
            </div>
          )}
        </div>
        {!thumbnail && (
          <Toolbar
            {...ToolbarConfig(
              () => data.showModal('updateRule'),
              () => onExportChart(),
              () => onZoomOut(),
              () => makeThumbnail(),
              () => setDraggable(!draggable),
              () => onLock()
            )}
            activations={
              data?.chart?.links
                ? Object.values(data.chart.links).filter((l) => l.condition)
                : []
            }
          />
        )}
        <AssignmentModal
          isOpen={data.modals.includes('updateAssignment')}
          onClose={() => data.hideModal('updateAssignment')}
          {...AssignmentModalConfig(
            () => data.hideModal('updateAssignment'),
            (e) => data.updateAssignment(e),
            toJS(data.activeAssigment),
            toJS(data.nodes)
          )}
          completions={toJS(data.completions)}
          functions={toJS(data.functions)}
          setAssignment={data.setTemporaryAssignment}
        />
        <ActivationModal
          isOpen={data.modals.includes('updateActivation')}
          subsetId={toJS(data.incommingData.id)}
          onClose={() => {
            // when close button is click when creating new link, remove link
            if (toJS(data.activeActivation.newLink)) {
              delete data.chart.links[data.activeActivation.linkId];
            }
            // hide modal
            data.hideModal('updateActivation');
          }}
          {...ActivationModalConfig(
            // when cancel button is clicked
            () => {
              // when close button is click when creating new link, remove link
              if (toJS(data.activeActivation.newLink)) {
                delete data.chart.links[data.activeActivation.linkId];
              }

              // hide modal
              data.hideModal('updateActivation');
            },

            // when update button is clicked
            (e) =>
              data.updateActivation(e).then((e) => {
                data.hideModal('updateActivation');
              }),

            // when delete button is clicked
            (e) => data.showModal('removeItem', null, 'activation'),

            // is it a new link or not, used for hide/show remove button
            toJS(data.activeActivation.newLink)
          )}
          completions={toJS(data.completions)}
          functions={toJS(data.functions)}
          setActivation={data.setTemporaryActivation}
          activeActivation={toJS(data.activeActivation)}
        />
        <RemoveModal
          isOpen={data.modals.includes('removeItem')}
          onClose={() => data.hideModal('removeItem')}
          title={{ text: 'Are you sure you want to remove this condition?' }}
          content={{ text: 'Placeholder' }}
          buttons={[
            {
              color: 'stable-500',
              text: 'Cancel',
              textColor: 'positive',
              outline: true,
              action: () => data.hideModal('removeItem'),
            },
            {
              text: 'Continue',
              color: 'assertive',
              action: () =>
                data.itemToBeRemoved === 'activation'
                  ? data.updateActivation('empty').then(() => {
                      data.hideModal('removeItem');
                      data.hideModal('updateActivation');
                    })
                  : data.itemToBeRemoved.includes('assignment')
                  ? data.removeChild('assignment').then(() => {
                      data.hideModal('removeItem');
                    })
                  : data.itemToBeRemoved.includes('subset')
                  ? data
                      .removeChild('subset')
                      .then(() => data.hideModal('removeItem'))
                  : data.removeNode().then(() => {
                      data.hideModal('removeItem');
                      data.hideModal('updateActivation');
                    }),
            },
          ]}
        />
        <RuleModal
          isOpen={data.modals.includes('updateRule')}
          onClose={() => data.hideModal('updateRule')}
          onSelect={(v) => data.setSelectedRule(v)}
          header={{
            title: {
              text: 'Add rule',
            },
          }}
          footer={{
            buttonsRight: [
              {
                color: 'stable-500',
                text: 'Cancel',
                textColor: 'positive',
                outline: true,
                action: () => data.hideModal('updateRule'),
              },
              {
                text: 'Save changes',
                action: () => data.addRule(toJS(data.chart), 'updateRule'),
              },
            ],
          }}
          url={data.paths.rules_search}
          data={data.rules}
        />
      </div>
    ) : null;
  })
);

export default Chart;
