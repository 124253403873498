import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['select', 'input'];

  toggle() {
    if (!this.hasSelectTarget || !this.hasInputTarget) return

    const newDisable = this.selectTarget.value === 'Auto';
    this.inputTarget.disabled = newDisable;
    if (newDisable)
      this.inputTarget.value = '';
  }
}
