import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['orderParam'];

  order(event) {
    event.preventDefault();
    const button = event.currentTarget;
    const column = button.dataset.orderColumn;
    const direction = button.dataset.orderDirection;

    this.orderParamTarget.value = `${column} ${direction}`;
    this.orderParamTarget.form.requestSubmit();
  }
  
}
