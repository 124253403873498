import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import ReactSelect, { components } from 'react-select';
import Title from '@components/text/title';
import deburr from 'lodash.deburr';
import styles from './select.module.scss';

const Rails = typeof window !== 'undefined' ? require('@rails/ujs') : null;

const cx = classNames.bind(styles);

const Select = ({
  options,
  id,
  type,
  name,
  disabled,
  placeholder,
  multi,
  attributes,
  data,
  input,
  onChange,
  noClose,
  limit,
  value,
  defaultOption,
  hideSelectedOptions,
  className,
}) => {
  const shouldIClose = noClose ? false : !attributes || !attributes.form;
  const [error, setError] = useState('');
  const [localOptions, setLocalOptions] = useState(
    options?.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    })
  );
  const [localValue, setValue] = useState(
    options.filter(
      (prop) =>
        prop.checked ||
        (Array.isArray(value) && value.includes(prop.value)) ||
        prop.value === value
    )
  );
  const classes = cx(
    {
      select: true,
      multi,
    },
    className
  );

  const changeValue = (action, option) => {
    if (option.removedValue && option.removedValue.isFixed) return;
    setValue(action);
    if (!shouldIClose) return;
    if (input && typeof input === 'function') input();
    if (data && Object.entries(data).length > 0) {
      const elem = document.querySelector(`[name="${action.name}"]`);
      elem.setAttribute('value', action.value);
      Object.entries(data).forEach((entry) => {
        elem.setAttribute(`data-${entry[0]}`, entry[1]);
      });
      Rails.fire(elem, 'change');
    }
    if (typeof onChange === 'function') {
      onChange(action, 'select');
    }
  };

  useEffect(() => {
    setValue(
      options.filter(
        (prop) =>
          prop.checked ||
          (Array.isArray(value) && value.includes(prop.value)) ||
          prop.value === value
      )
    );

    setLocalOptions(
      options?.sort((a, b) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      })
    );
  }, [options]);

  useEffect(() => {
    if (limit) setLocalOptions(localOptions.splice(0, limit));
  }, []);

  const filterOptions = (e) => {
    if (e.length !== 0) {
      const originalOptions = [...options]
        .filter((v) =>
          deburr(v.value.toUpperCase()).includes(deburr(e.toUpperCase()))
        )
        .splice(0, limit);
      setLocalOptions(originalOptions);
      if (limit === originalOptions.length) {
        setError('Please provide 3 or more characters');
      }
    } else setError('');
  };

  const MultiValueRemove = (props) => {
    if (data?.isFixed) {
      return;
    }
    return <components.MultiValueRemove {...props} />;
  };

  const ClearIndicator = (props) => {
    if (options?.some((item) => item.isFixed === true)) {
      return null;
    }
    return <components.ClearIndicator {...props} />;
  };

  const filterOption = (option, inputValue) => {
    const result = options.filter(
      (o) =>
        o.value.toString().includes(inputValue) ||
        o.label.toString().includes(inputValue)
    );

    // defaultOption filter: Option that is always shown, when the other options are not found when searching.
    if (option.value === defaultOption && defaultOption != '') {
      return result.length === 0;
    }

    // Case insensitive
    return (
      option.value.toString().includes(inputValue) ||
      option.label.toString().includes(inputValue) ||
      option.value
        .toString()
        .toUpperCase()
        .includes(inputValue.toUpperCase()) ||
      option.label.toString().toUpperCase().includes(inputValue.toUpperCase())
    );
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter') e.preventDefault();
  };

  return (
    <>
      {error && (
        <Title text={error} size={'label'} inline color={'assertive'} />
      )}
      <ReactSelect
        id={id}
        className={classes}
        classNamePrefix={'select'}
        // set captureMenuScroll to false to prevent:
        // non-passive event listener in scroll blocking events
        filterOption={filterOption}
        captureMenuScroll={false}
        type={type}
        name={name}
        isDisabled={disabled}
        placeholder={placeholder}
        options={localOptions}
        isMulti={multi}
        value={localValue}
        form={attributes ? attributes.form : ''}
        closeMenuOnSelect={shouldIClose}
        onInputChange={limit && filterOptions}
        // onMenuClose={onClose}
        hideSelectedOptions={hideSelectedOptions}
        components={{
          MultiValueRemove,
          ClearIndicator,
        }}
        onKeyDown={onKeyDown}
        styles={{
          multiValueRemove: (base, state) =>
            state.data.isFixed
              ? {
                  ...base,
                  display: 'none',
                }
              : base,
          dropdownIndicator: (provided, state) => ({
            ...provided,
            transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
          }),
        }}
        menuPlacement={'auto'}
        onChange={(action, actionType) => {
          changeValue(action, actionType);
        }}
      />
    </>
  );
};

Select.propTypes = {
  /** The input type */
  type: PropTypes.oneOf(['select', 'multi-select', 'asynchronous-select']),
  /** The name of the input */
  name: PropTypes.string,
  /** The placeholder text */
  placeholder: PropTypes.string,
  /** Whether the input is disabled */
  disabled: PropTypes.bool,
  /** A unique id for the input */
  id: PropTypes.string.isRequired,
  /** Ability to close */
  noClose: PropTypes.bool,
  /** Options to chose from */
  options: PropTypes.arrayOf(PropTypes.shape({})),
  /** Allows multiple answers */
  multi: PropTypes.bool,
  /** Allows extra attributes */
  attributes: PropTypes.arrayOf(PropTypes.shape({})),
  /** Additional data */
  data: PropTypes.shape({}),
  /** Input funcitonality */
  input: PropTypes.func,
  /** onChange functionality */
  onChange: PropTypes.func,
  /** Limit the returned options */
  limit: PropTypes.number,
  /** Default option that will be shown, when the search criteria doesn't match the other options */
  defaultOption: PropTypes.string,
  /** Show or hide the selected options in a dropdown menu */
  hideSelectedOptions: PropTypes.bool,
  className: PropTypes.string,
};

Select.defaultProps = {
  attributes: [{}],
  data: null,
  disabled: false,
  input: null,
  limit: null,
  multi: false,
  name: 'asynchronous-select',
  noClose: false,
  onChange: null,
  options: [],
  placeholder: '',
  type: 'asynchronous-select',
  defaultOption: '',
  hideSelectedOptions: true,
  className: '',
};

// Needed for Storybook
Select.displayName = 'Select';

export default Select;
