import React, { useRef, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import axios from 'axios';
import AceEditor from 'react-ace';
import Tooltip from '@components/ui/tooltip';
import Icon from '@components/ui/icon';
import Badge from '@components/badge/badge';
import Title from '@components/text/title';
import Divider from '@components/ui/divider';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/theme-github';
import {
  addCompleter,
  setCompleters,
} from 'ace-builds/src-noconflict/ext-language_tools';
import Simple from '../simple';
import editorStyles from './codeEditor.module.scss';
import styles from '../element/element.module.scss';

const cx = classNames.bind({ ...styles, ...editorStyles });

const CodeEditor = ({
  completions,
  functions,
  initialValue,
  inputName,
  url,
  label,
  method,
  autoFocus,
  tooltip,
  validator,
  initialErrors,
  setValidity,
  setContent,
  subsetId,
  storybook,
  rowCount,
  lineNumbers,
  mode,
  className,
  readOnly,
}) => {
  const single = !lineNumbers && rowCount == 1;
  const editor = useRef();
  const [errors, setErrors] = useState(initialErrors);
  const [initialised, setInitialised] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [valueBeautify, setValueBeautify] = useState('');
  const [localAnnotations, setLocalAnnotations] = useState([]);
  const timeoutId = useRef();
  const classes = cx(
    {
      input: true,
      editor: true,
      error: errors && errors.length > 0,
      single,
      [`mode-${mode}`]: true,
    },
    className
  );
  let source;
  useEffect(() => {
    setInitialised(true);
    if (autoFocus) {
      const row = editor.current.editor.session.getLength() - 1;
      const column = editor.current.editor.session.getLine(row).length;
      editor.current.editor.gotoLine(row + 1, column);
      editor.current.editor.focus();
    }
  }, [initialValue]);

  const validate = () => {
    /** Catch empty url for storybook */
    if (url === '') return;

    const { session } = editor.current.editor;
    const callback = {
      url,
      method,
    };
    source = axios.CancelToken.source();
    const cancelToken = source.token;

    axios({
      method: 'POST',
      url: callback.url,
      data: `evaluation[condition]=${encodeURIComponent(
        session.doc.$lines.join(' ')
      )}&evaluation[validator]=${validator}&evaluation[subset_id]=${subsetId}`,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
      },
      cancelToken,
    }).then((resp) => {
      const ruleErrors = [];
      session.doc.$lines.map((words, index) => {
        words.split(' ').map((word) => {
          if (Object.keys(resp.data.errors).includes(word.replace(/\s/g, ''))) {
            if (ruleErrors.findIndex((x) => x.index === index) === -1) {
              ruleErrors.push({
                word,
                index,
                errors: [
                  {
                    key: word,
                    error: resp.data.errors[word.replace(/\s/g, '')],
                  },
                ],
              });
            }
          }
        });
      });
      const genericErrors = [];
      Object.keys(resp.data.errors).map((error) => {
        if (error === 'syntax' || error === 'ending_token') {
          genericErrors.push(resp.data.errors[error]);
        } else {
          genericErrors.push(`${error}: ${resp.data.errors[error]}`);
        }
      });

      if (setValidity && setContent) {
        if (ruleErrors.length > 0 || genericErrors.length > 0)
          setValidity(false);
        else {
          setValidity(true);
        }
      }
      setErrors(genericErrors);
      setLocalAnnotations(ruleErrors);
    });
  };

  useEffect(() => {
    if (setContent) setContent(value);
    if (!initialised) return;
    if (!storybook && setValidity) setValidity(false);
    clearTimeout(timeoutId.current);
    if (source) source.cancel();
    timeoutId.current = setTimeout(() => {
      validate();
    }, 1000);
  }, [value]);

  useEffect(() => {
    const newCompleter = {
      getCompletions(editor, session, pos, prefix, callback) {
        callback(null, completions);
      },
    };

    const completionValues = completions.map((x) => x.value);

    const functionValues = functions.map((x) => x.name.replace('()', ''));

    const keywordMapper = (v) =>
      v === 'true'
        ? 'constant.true'
        : v === 'false'
        ? 'constant.false'
        : v === 'null'
        ? 'constant.null'
        : functionValues.includes(v)
        ? 'constant.function'
        : completionValues.includes(v)
        ? 'completion'
        : 'identifier';

    const currentEditor = editor.current.editor;
    const session = currentEditor.getSession();
    currentEditor.setOption('showGutter', lineNumbers);
    currentEditor.setOption('showLineNumbers', lineNumbers);
    if (single) {
      currentEditor.container.style.lineHeight = '2.5em';
      currentEditor.renderer.updateFontSize();
    }
    session.setMode(`ace/mode/text`, () => {
      const highlightRules = session.$mode.$highlightRules;

      // TODO: make some nice abstractions for this
      if (mode == 'jsonnet') {
        highlightRules.$rules = {
          start: [
            {
              include: '#expression',
            },
            {
              include: '#keywords',
            },
          ],
          '#builtin-functions': [
            {
              token: 'support.function.jsonnet',
              regex: /\bstd[.]extVar\b/,
              comment: 'External Variables',
            },
            {
              token: 'support.function.jsonnet',
              regex:
                /\bstd[.](?:thisFile|type|length|get|objectHas|objectFields|objectValues|objectHasAll|objectFieldsAll|objectValuesAll|prune|mapWithKey)\b/,
              comment: 'Types and Reflection',
            },
            {
              token: 'support.function.jsonnet',
              regex:
                /\bstd[.](?:abs|sign|max|min|pow|exp|log|exponent|mantissa|floor|ceil|sqrt|sin|cos|tan|asin|acos|atan)\b/,
              comment: 'Mathematical Utilities 1',
            },
            {
              token: 'support.function.jsonnet',
              regex: /\bstd[.]clamp\b/,
              comment: 'Mathematical Utilities 2',
            },
            {
              token: 'support.function.jsonnet',
              regex: /\bstd[.]assertEqual\b/,
              comment: 'Assertions and Debugging',
            },
            {
              token: 'support.function.jsonnet',
              regex:
                /\bstd[.](?:toString|codepoint|char|substr|findSubstr|startsWith|endsWith|stripChars|lstripChars|rstripChars|split|splitLimit|strReplace|asciiUpper|asciiLower|stringChars|format|escapeStringBash|escapeStringDollars|escapeStringJson|escapeStringPython)\b/,
              comment: 'String Manipulation',
            },
            {
              token: 'support.function.jsonnet',
              regex: /\bstd[.]parse(?:Int|Octal|Hex|Json|Yaml)\b/,
              comment: 'Parsing 1',
            },
            {
              token: 'support.function.jsonnet',
              regex: /\bstd[.](?:encodeUTF8|decodeUTF8)\b/,
              comment: 'Parsing 2',
            },
            {
              token: 'support.function.jsonnet',
              regex:
                /\bstd[.]manifest(?:Ini|Python|PythonVars|JsonEx|JsonMinified|YamlDoc|YamlStream|XmlJsonml|TomlEx)\b/,
              comment: 'Manifestation',
            },
            {
              token: 'support.function.jsonnet',
              regex:
                /\bstd[.](?:makeArray|member|count|find|map|mapWithIndex|filterMap|flatMap|filter|foldl|foldr|range|repeat|slice|join|lines|flattenArrays|reverse|sort|uniq)\b/,
              comment: 'Arrays',
            },
            {
              token: 'support.function.jsonnet',
              regex: /\bstd[.]set(?:Inter|Union|Diff|Member)?\b/,
              comment: 'Sets',
            },
            {
              token: 'support.function.jsonnet',
              regex: /\bstd[.](?:base64|base64DecodeBytes|base64Decode|md5)\b/,
              comment: 'Encoding',
            },
            {
              token: 'support.function.jsonnet',
              regex: /\bstd[.]mergePatch\b/,
              comment: 'JSON Merge Patch',
            },
            {
              token: 'support.function.jsonnet',
              regex: /\bstd[.]trace\b/,
              comment: 'Debugging',
            },
          ],
          '#comment': [
            {
              token: 'comment.block.jsonnet',
              regex: /\/\*/,
              push: [
                {
                  token: 'comment.block.jsonnet',
                  regex: /\*\//,
                  next: 'pop',
                },
                {
                  defaultToken: 'comment.block.jsonnet',
                },
              ],
            },
            {
              token: 'comment.line.jsonnet',
              regex: /\/\/.*$/,
            },
            {
              token: 'comment.block.jsonnet',
              regex: /#.*$/,
            },
          ],
          '#double-quoted-strings': [
            {
              token: 'string.quoted.double.jsonnet',
              regex: /"/,
              push: [
                {
                  token: 'string.quoted.double.jsonnet',
                  regex: /"/,
                  next: 'pop',
                },
                {
                  token: 'constant.character.escape.jsonnet',
                  regex: /\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/,
                },
                {
                  token: 'invalid.illegal.jsonnet',
                  regex: /\\[^"\\\/bfnrtu]/,
                },
                {
                  defaultToken: 'string.quoted.double.jsonnet',
                },
              ],
            },
          ],
          '#expression': [
            {
              include: '#literals',
            },
            {
              include: '#comment',
            },
            {
              include: '#single-quoted-strings',
            },
            {
              include: '#double-quoted-strings',
            },
            {
              include: '#triple-quoted-strings',
            },
            {
              include: '#builtin-functions',
            },
            {
              include: '#functions',
            },
          ],
          '#functions': [
            {
              token: ['entity.name.function.jsonnet', 'meta.function'],
              regex: /\b([a-zA-Z_][a-z0-9A-Z_]*)(\s*\()/,
              push: [
                {
                  token: 'meta.function',
                  regex: /\)/,
                  next: 'pop',
                },
                {
                  include: '#expression',
                },
                {
                  defaultToken: 'meta.function',
                },
              ],
            },
          ],
          '#keywords': [
            {
              token: 'keyword.operator.jsonnet',
              regex: /[!:~\+\-&\|\^=<>\*\/%]/,
            },
            {
              token: 'keyword.other.jsonnet',
              regex: /\$/,
            },
            {
              token: 'keyword.other.jsonnet',
              regex: /\b(?:self|super|import|importstr|local|tailstrict)\b/,
            },
            {
              token: 'keyword.control.jsonnet',
              regex: /\b(?:if|then|else|for|in|error|assert)\b/,
            },
            {
              token: 'storage.type.jsonnet',
              regex: /\bfunction\b/,
            },
            {
              token: 'variable.parameter.jsonnet',
              regex: /[a-zA-Z_][a-z0-9A-Z_]*\s*(?::::|\+:::)/,
            },
            {
              token: 'entity.name.type',
              regex: /[a-zA-Z_][a-z0-9A-Z_]*\s*(?:::|\+::)/,
            },
            {
              token: 'variable.parameter.jsonnet',
              regex: /[a-zA-Z_][a-z0-9A-Z_]*\s*(?::|\+:)/,
            },
          ],
          '#literals': [
            {
              token: 'constant.language.jsonnet',
              regex: /\b(?:true|false|null)\b/,
            },
            {
              token: 'constant.numeric.jsonnet',
              regex: /\b\d+(?:[Ee][+-]?\d+)?\b/,
            },
            {
              token: 'constant.numeric.jsonnet',
              regex: /\b\d+[.]\d*(?:[Ee][+-]?\d+)?\b/,
            },
            {
              token: 'constant.numeric.jsonnet',
              regex: /\b[.]\d+(?:[Ee][+-]?\d+)?\b/,
            },
          ],
          '#single-quoted-strings': [
            {
              token: 'string.quoted.double.jsonnet',
              regex: /'/,
              push: [
                {
                  token: 'string.quoted.double.jsonnet',
                  regex: /'/,
                  next: 'pop',
                },
                {
                  token: 'constant.character.escape.jsonnet',
                  regex: /\\(?:['\\\/bfnrt]|u[0-9a-fA-F]{4})/,
                },
                {
                  token: 'invalid.illegal.jsonnet',
                  regex: /\\[^'\\\/bfnrtu]/,
                },
                {
                  defaultToken: 'string.quoted.double.jsonnet',
                },
              ],
            },
          ],
          '#triple-quoted-strings': [
            {
              token: 'string.quoted.triple.jsonnet',
              regex: /\|\|\|/,
              push: [
                {
                  token: 'string.quoted.triple.jsonnet',
                  regex: /\|\|\|/,
                  next: 'pop',
                },
                {
                  defaultToken: 'string.quoted.triple.jsonnet',
                },
              ],
            },
          ],
        };
      } else if (mode == 'dentaku') {
        highlightRules.$rules = {
          start: [{ include: '#tokens' }],
          '#tokens': [
            {
              token: 'constant.numeric', // float
              regex: '[+-]?\\d+(?:(?:\\.\\d*)?(?:[eE][+-]?\\d+)?)?\\b',
            },
            {
              token: 'keyword.operator',
              regex:
                '\\+|\\-|\\/|\\/\\/|%|<@>|@>|<@|&|\\^|~|<|>|<=|=>|==|!=|<>|=',
            },
            {
              token: keywordMapper,
              regex: '[\\.a-zA-Z_$][\\.a-zA-Z0-9_$]*\\b',
            },
          ],
        };
      } else if (mode == 'interpolation') {
        highlightRules.$rules = {
          start: [
            {
              token: 'string.interpolated.open',
              regex: /\{\{/,
              push: [
                {
                  token: 'string.interpolated.close',
                  regex: /\}\}/,
                  next: 'pop',
                },
                { include: '#dentaku' },
              ],
            },
          ],
          '#dentaku': [
            {
              token: 'constant.numeric', // float
              regex: '[+-]?\\d+(?:(?:\\.\\d*)?(?:[eE][+-]?\\d+)?)?\\b',
            },
            {
              token: 'keyword.operator',
              regex:
                '\\+|\\-|\\/|\\/\\/|%|<@>|@>|<@|&|\\^|~|<|>|<=|=>|==|!=|<>|=',
            },
            {
              token: keywordMapper,
              regex: '[\\.a-zA-Z_$][\\.a-zA-Z0-9_$]*\\b',
            },
          ],
        };
      }

      highlightRules.normalizeRules();
      // force recreation of tokenizer
      session.$mode.$tokenizer = null;
      session.bgTokenizer.setTokenizer(session.$mode.getTokenizer());
      // force re-highlight whole document
      session.bgTokenizer.start(0);
    });

    // Clear out old completers and add new one
    editor.current.editor.completers = editor.current.editor.completers.slice();
    editor.current.editor.completers.push(newCompleter);
  }, [completions]);

  useEffect(() => {
    if (editor && editor.current && editor.current.editor) {
      // Calculate how many characters should be visible
      const maxLength = completions
        .map((completion) => completion.meta.length + completion.name.length)
        .sort()
        .reverse()[0];
      // Initialize completer
      editor.current.editor.execCommand('startAutocomplete');
      // Deactive completer
      if (editor.current.editor.completer) {
        editor.current.editor.completer.detach();
      }
      // Set the correct width of the popup
      if (editor.current.editor.completer?.popup) {
        editor.current.editor.completer.popup.container.style.width = `${
          maxLength * 10
        }px`;
        editor.current.editor.completer.popup.resize();
      }
    }

    if (readOnly) {
      setValueBeautify(JSON.stringify(JSON.parse(value), null, 2));
    }
  }, []);

  return (
    <div>
      {(label || errors) && (
        <label className={styles.label}>
          {label && (
            <Title
              text={label}
              size={'label'}
              inline
              color={errors && errors.length > 0 ? 'assertive' : 'balanced-500'}
            />
          )}
          {tooltip && (
            <Tooltip
              simple
              trigger={'mouseenter'}
              button={{
                color: 'transparent',
                icon: { name: 'info' },
              }}
              content={{ text: tooltip }}
            />
          )}
          {errors &&
            errors.map((error, index) => (
              <Badge
                key={index}
                color={'assertive'}
                size={'s'}
                className={styles.badge}
                content={{ text: error }}
              />
            ))}
        </label>
      )}
      {(label || errors.length > 0) && (
        <Divider width={0} height={5} color={'transparent'} />
      )}
      <div className={editorStyles.wrapper}>
        <AceEditor
          className={classes}
          ref={editor}
          value={readOnly ? valueBeautify : value}
          mode='text'
          theme='github'
          onChange={(e) => setValue(e)}
          name='UNIQUE_ID_OF_DIV'
          width='100%'
          height={`${rowCount * 25}px`}
          fontSize={'var(--font-size-l)'}
          fontFamily={'var(--font-stack-mono)'}
          editorProps={{ $blockScrolling: true }}
          highlightSelectedWord
          setOptions={{
            hasCssTransforms: true,
            useWorker: false,
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
          }}
          showGutter
          readOnly={readOnly}
        />
        <div className={editorStyles.errorwrapper}>
          {localAnnotations.map(
            (annotation, i) =>
              annotation.errors.length > 0 && (
                <div
                  key={i}
                  className={editorStyles.errorbar}
                  style={{ top: `${annotation.index * 24}px` }}
                >
                  <Tooltip
                    content={annotation.errors}
                    position='bottom'
                    trigger={'mouseenter'}
                    editor
                  >
                    <Icon
                      icon={'chevron-right'}
                      color={'royal'}
                      width={10}
                      height={10}
                      className={editorStyles.errorChev}
                    />
                  </Tooltip>
                  <div className={editorStyles.errorOverlay} />
                </div>
              )
          )}
        </div>
        <Simple type={'hidden'} name={inputName} value={value} />
      </div>
    </div>
  );
};

CodeEditor.propTypes = {
  /* Name of input in form */
  inputName: PropTypes.string,
  /* Default value */
  initialValue: PropTypes.string,
  /* Set of completions that can be suggested by the editor */
  completions: PropTypes.arrayOf(PropTypes.shape({})),
  /* Set the function that are shown with a color by the editor */
  functions: PropTypes.arrayOf(PropTypes.shape({})),
  // Add errors per row
  initialErrors: PropTypes.arrayOf(PropTypes.string),
  // Url for request
  url: PropTypes.string,
  /** The label text */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  // Method of request
  method: PropTypes.string,
  /* Whether the editor needs to be autofocussed */
  autoFocus: PropTypes.bool,
  /* Tooltip text for the code editor */
  tooltip: PropTypes.string,
  /* Add custom functionality for Storybook */
  storybook: PropTypes.bool,
  /** Set number of rows */
  rowCount: PropTypes.number,
  /* Validator to be used on validation */
  validator: PropTypes.string,
  /* Allows a subsetset id to be send */
  subsetId: PropTypes.number,
  /* Whether to show the gutter with line numbers on the left side of the editor */
  lineNumbers: PropTypes.bool,
  /* Language mode (determines the lexer to be used) */
  mode: PropTypes.oneOf(['dentaku', 'jsonnet', 'interpolation']),
  // Add a custom className
  className: PropTypes.string,
  // Makes the code editor read only
  readOnly: PropTypes.bool,
};

CodeEditor.defaultProps = {
  inputName: '',
  initialValue: '',
  completions: [],
  functions: [],
  initialErrors: [],
  label: null,
  url: '/admin/evaluation_validations',
  method: 'POST',
  autoFocus: false,
  tooltip: '',
  storybook: false,
  validator: 'condition',
  rowCount: 5,
  subsetId: null,
  lineNumbers: true,
  mode: 'dentaku',
  readOnly: false,
};

CodeEditor.displayName = 'CodeEditor';

export default CodeEditor;
