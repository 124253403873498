import { Controller } from '@hotwired/stimulus';

// This controller needs some refactoring: https://remarkgroup.atlassian.net/browse/BRMS-3642
export default class extends Controller {
  static targets = ['input', 'output'];
  static values = { showIf: String };

  connect() {
    setTimeout(() => {
      this.toggle();
    }, 1);
  }
  toggle() {
    const option = this.inputTarget.querySelector('option');
    if (option && option.value != this.showIfValue) {
      this.outputTarget.style.display = 'none';
    } else if (this.inputTarget.innerHTML.includes(this.showIfValue)) {
      this.outputTarget.style.display = 'flex';
    }
  }
}
