import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  static values = {
    source: String,
    session: Number,
    token: String,
    url: String,
    railsEnv: String,
    completedUrl: String,
  };

  connect() {
    const head = document.getElementsByTagName('head')[0];
    const existingScript = Array.from(head.getElementsByTagName('script')).find(
      (script) => script.src === this.sourceValue
    );

    if (!existingScript) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;

      script.onload = () => {
        this.renderWidget();
      };

      script.src = this.sourceValue;
      head.appendChild(script);
    } else {
      this.renderWidget();
    }

    document.addEventListener('completed', (e) => {
      get(this.completedUrlValue, {
        responseKind: 'turbo-stream',
      });
    });
  }

  renderWidget() {
    if (window.BRMSWidget) {
      const widgetHash = {
        mode: 'preview',
        session: this.sessionValue,
        token: this.tokenValue,
        url: this.urlValue,
        railsEnv: this.railsEnvValue,
        report: false,
      };

      window.BRMSWidget.create(widgetHash);
    }
  }
}
