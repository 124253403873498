// Import and register all your controllers from the importmap under controllers/*
import { application } from './application';

import FormController from './form_controller';
application.register('form', FormController);

import MountReactController from './mount_react_controller';
application.register('mount-react', MountReactController);

import ModalController from './modal_controller';
application.register('modal', ModalController);

import DynamicModalController from './dynamic_modal_controller';
application.register('dynamic-modal', DynamicModalController);

import SortableController from './sortable_controller';
application.register('sortable', SortableController);

import OrderController from './order_controller';
application.register('order', OrderController);

import RegenerateController from './regenerate_controller';
application.register('regenerate', RegenerateController);

import VcRiskFactorRuleController from './vc-risk-factor-rule-controller';
application.register('vc-risk-factor-rule', VcRiskFactorRuleController);

import SuccessiveDisableRemovalController from './successive-disable-removal-controller';
application.register(
  'successive-disable-removal',
  SuccessiveDisableRemovalController
);

import WidgetController from './widget_controller';
application.register('widget', WidgetController);

import inputConfirmationToggleController from './input_confirmation_toggle_controller';
application.register(
  'input-confirmation-toggle',
  inputConfirmationToggleController
);

import ClipboardController from './clipboard_controller';
application.register('clipboard', ClipboardController);

import EnableButtonController from './enable_button_controller';
application.register('enable-button', EnableButtonController);

import PreviewLoaderController from './preview_loader_controller';
application.register('preview-loader', PreviewLoaderController);

import CheckboxToggleController from './checkbox_toggle_controller';
application.register('checkbox-toggle', CheckboxToggleController);

import SelectToggleController from './select_toggle_controller';
application.register('select-toggle', SelectToggleController);

import ToggleController from './toggle_controller';
application.register('toggle', ToggleController);

import ToggleConfirmationController from './toggle_confirmation_controller';
application.register('toggle-confirmation', ToggleConfirmationController);

import PreviewTopbarController from './preview_topbar_controller';
application.register('preview-topbar', PreviewTopbarController);

import TableOptionsController from "./table_options_controller";
application.register('table-options', TableOptionsController);

//overwrite data-turbo-confirm modal
const newConfirmMethod = async (message, formElement) => {
  const modal = document.querySelector(
    'brms-modal[data-selector="confirmation-modal"]'
  );
  if (!modal) return;

  const title = modal.querySelector(
    '[data-selector="confirmation-modal-title"]'
  );
  const content = modal.querySelector(
    '[data-selector="confirmation-modal-content"]'
  );
  const cancelBtn = modal.querySelector(
    '[event-id="confirmation-modal-cancel"]'
  );
  const confirmBtn = modal.querySelector(
    '[event-id="confirmation-modal-confirm"]'
  );
  const strings = JSON.parse(message);
  title && title.setAttribute('text', strings.title);
  content && content.setAttribute('text', strings.description);
  cancelBtn && cancelBtn.setAttribute('text', strings.cancel);
  confirmBtn && confirmBtn.setAttribute('text', strings.confirm);
  modal.removeAttribute('hidden');
  return new Promise((resolve, reject) => {
    document.addEventListener(
      `brms-button:click:confirmation-modal-close`,
      () => {
        resolve(false);
        modal.setAttribute('hidden', '');
        formElement.remove();
      }
    );
    document.addEventListener(
      `brms-button:click:confirmation-modal-cancel`,
      () => {
        resolve(false);
        modal.setAttribute('hidden', '');
        formElement.remove();
      }
    );
    document.addEventListener(
      `brms-button:click:confirmation-modal-confirm`,
      () => {
        modal.setAttribute('hidden', '');
        resolve(true);
      }
    );
  });
};
export { newConfirmMethod };

Turbo.setConfirmMethod(newConfirmMethod);
