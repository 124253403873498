const toolbarConfig = (
  showModal,
  exportChart,
  onZoomOut,
  makeThumbnail,
  openDraggable,
  onLocked
) => ({
  buttons: [
    {
      outline: true,
      icon: {
        name: 'diagram',
        height: 18,
        width: 20,
      },
      action: () => onZoomOut(),
    },
    // {
    //   outline: true,
    //   icon: {
    //     icon: 'eye-round',
    //     width: 12,
    //     height: 12,
    //   },
    //   action: () => makeThumbnail(),
    // },
    {
      text: 'Add rule',
      icon: {
        name: 'plus',
        height: 18,
        width: 20,
      },
      color: 'positive',
      action: (e) => showModal(e),
    },
    {
      outline: true,
      text: 'Export',
      color: 'positive',
      icon: {
        name: 'download',
        height: 18,
        width: 20,
      },
      action: () => exportChart(),
    },
    {
      outline: true,
      icon: {
        name: 'eye-round',
        height: 18,
        width: 20,
      },
      action: () => openDraggable(),
    },
    onLocked(),
  ],
  tooltips: [
    {
      legend: true,
      simple: false,
      button: {
        outline: true,
        icon: {
          name: 'info',
          height: 18,
          width: 20,
        },
        color: 'positive',
      },
      content: [
        {
          title: {
            text: 'Start',
          },
          sign: {
            icon: 'circle',
            color: 'positive',
          },
        },
        {
          title: {
            text: 'End',
          },
          sign: {
            icon: 'circle',
            color: 'energized',
          },
        },
        {
          title: {
            text: 'Error',
          },
          sign: {
            icon: 'circle',
            color: 'royal',
          },
        },
        {
          title: {
            text: 'Activation Condition',
          },
          sign: {
            icon: 'square',
          },
        },
        {
          title: {
            text: 'Rule',
          },
          sign: {
            icon: 'border',
          },
        },
        {
          title: {
            text: 'Assignment',
          },
          sign: {
            icon: 'dashed',
          },
        },
        {
          title: {
            text: 'Subset',
          },
          sign: {
            icon: 'dashed',
            color: 'positive',
            rotate: 'true',
          },
        },
      ],
    },
  ],
});

export default toolbarConfig;
